<template>
  <ul v-if="TLDRArray.length" class="tldr text-body">
    <li
      class="text-body tldr__item"
      :class="dynamicClasses"
      v-for="(tldr, i) in TLDRArray"
      :key="`${tldr}${i}`"
    >
      {{ tldr }}
    </li>
  </ul>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'ATldr',
  props: {
    tldr: propValidator([PROP_TYPES.STRING], false),
    fontBold: propValidator([PROP_TYPES.BOOLEAN], false, true),
    itemsToShow: propValidator([PROP_TYPES.NUMBER], false)
  },
  data() {
    return {}
  },
  computed: {
    TLDRArray() {
      try {
        const tldr = JSON.parse(this.tldr)
        if (!Array.isArray(tldr)) return []

        const tldrWithoutEmptyValues = tldr.filter(v => v !== '')
        return this.itemsToShow
          ? tldrWithoutEmptyValues.slice(0, this.itemsToShow)
          : tldrWithoutEmptyValues
      } catch (err) {
        this.$errorHandler(err, this, { showMessage: false })
        return []
      }
    },
    dynamicClasses() {
      return [{ bold: this.fontBold }]
    }
  }
}
</script>

<style lang="scss" scoped>
.tldr {
  .tldr__item {
    list-style-type: none;
    margin-left: 15px;
    margin-bottom: 5px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '.';
      font-size: 40px;
      line-height: 0;
      position: absolute;
      top: 1px;
      left: -15px;
    }
  }
}
</style>
