<template>
  <ul class="article-socials__wrapper" :class="{ expanded }">
    <li class="article-socials__social">
      <a-action-icon
        :action-icon="ACTION_ICON.TWITTER"
        :width="35"
        :height="35"
        v-bind="socials.twitter"
      />
    </li>
    <li class="article-socials__social">
      <a-action-icon
        :action-icon="ACTION_ICON.TELEGRAM"
        :width="35"
        :height="35"
        v-bind="socials.telegram"
      />
    </li>
    <li
      class="article-socials__social comment-count"
      :class="dynamicCommentCountClass"
    >
      <a-action-icon
        :action-icon="ACTION_ICON.FL_COMMENT_COUNT"
        :width="35"
        :height="35"
        :identifier="disqusIdentifier"
        :is-comment-counter-visible.sync="isCommentCounterVisible"
        :min-comment-count-to-show-counter="MIN_COMMENT_COUNT_TO_SHOW_COUNTER"
        :link="linkToArticle"
        :initial-comment-count="commentCount"
        class="article-socials__comment-count"
        @update="$emit('update-comment-count', $event)"
      />
      <button
        v-if="expanded"
        class="article-socials__article-link font-sans bold"
        :class="dynamicLinkClass"
        @click="redirectToArticle"
      >
        View Full Article with Comments
        <i class="arrow-right" />
      </button>
    </li>
  </ul>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import AActionIcon, { ACTION_ICON } from 'shared/AActionIcon'
import mixins from '@/utils/mixins'

const MIN_COMMENT_COUNT_TO_SHOW_COUNTER = 2

export default {
  name: 'ArticleSlotSocials',
  mixins: [mixins.urlGenerators],
  components: { AActionIcon },
  props: {
    articleId: propValidator([PROP_TYPES.STRING]),
    slug: propValidator([PROP_TYPES.STRING]),
    category: propValidator([PROP_TYPES.OBJECT], false),
    socials: propValidator([PROP_TYPES.OBJECT]),
    expanded: propValidator([PROP_TYPES.BOOLEAN], false, false),
    isMigrated: propValidator([PROP_TYPES.BOOLEAN], false, false),
    commentCount: propValidator([PROP_TYPES.NUMBER], false, 0)
  },
  data() {
    return {
      ACTION_ICON,
      MIN_COMMENT_COUNT_TO_SHOW_COUNTER,
      isCommentCounterVisible:
        this.commentCount >= MIN_COMMENT_COUNT_TO_SHOW_COUNTER
    }
  },
  computed: {
    dynamicCommentCountClass() {
      return {
        visible: this.isCommentCounterVisible
      }
    },
    dynamicLinkClass() {
      return {
        'no-left-margin': !this.isCommentCounterVisible
      }
    },
    linkToArticle() {
      return this.generateArticlePath({
        categorySlug: this.category.slug,
        slug: this.slug,
        isMigrated: this.isMigrated
      })
    },
    disqusIdentifier() {
      return this.isMigrated ? this.slug : this.articleId
    }
  },
  methods: {
    redirectToArticle() {
      this.$router.push(this.linkToArticle)
    }
  }
}
</script>

<style lang="scss" scoped>
.article-socials__wrapper {
  width: 145px;
  display: flex;
  justify-content: flex-end;

  .article-socials__social {
    margin-left: 20px;
    list-style-type: none;

    &.comment-count {
      display: inline-block;
      position: relative;
      margin-left: 0;
      transition: margin-left 0.5s ease-in-out;
    }

    &.comment-count.visible {
      margin-left: 20px;
    }
  }

  &.expanded {
    width: 100%;
    justify-content: flex-start;

    .article-socials__social.comment-count {
      display: inline-flex;
      align-items: center;
      order: -1;
      margin: 0 auto 0 0;
    }

    .article-socials__article-link {
      font-size: 16px;
      margin-left: 10px;

      &.no-left-margin {
        margin-left: 0;
      }

      &:hover {
        color: $c--main;
        transition: 0.1s;
      }

      .arrow-right {
        margin-bottom: 3px;
      }

      &:hover .arrow-right,
      &:hover .arrow-right::before,
      &:hover .arrow-right::after {
        background: $c--main;
        transition: 0.1s;
      }
    }

    .article-socials__right-arrow {
      display: inline-block;
      font-size: 23px;
      transform: scaleY(1.5);
      margin-bottom: 0;
    }
  }
}
</style>
