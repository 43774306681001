<template>
  <div class="expanded-content__wrapper">
    <collapse-transition>
      <div v-if="isExpanded" class="expanded-content__content">
        <div class="expanded-content__slot-wrapper article-body">
          <slot name="top-expanded-content" />
        </div>
        <article-slot-socials
          :articleId="articleId"
          :socials="socials"
          :category="category"
          :slug="slug"
          :is-migrated="isMigrated"
          :comment-count="commentCount"
          expanded
          class="expanded-content__socials"
        />
        <slot name="bottom-expanded-content" :is-expanded="isExpanded" />
      </div>
    </collapse-transition>

    <button
      v-if="!isExpanded"
      class="see-more__arrow-down"
      aria-label="See More"
      @click="onExpand"
    />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import ArticleSlotSocials from '../ArticleSlotSocials'

export default {
  name: 'ArticleSlotExpandedContent',
  components: { ArticleSlotSocials },
  model: {
    prop: 'isExpanded',
    event: 'expand'
  },
  props: {
    articleId: propValidator([PROP_TYPES.STRING]),
    slug: propValidator([PROP_TYPES.STRING]),
    category: propValidator([PROP_TYPES.OBJECT], false),
    isExpanded: propValidator([PROP_TYPES.BOOLEAN], false, false),
    socials: propValidator([PROP_TYPES.OBJECT]),
    isMigrated: propValidator([PROP_TYPES.BOOLEAN], false, false),
    commentCount: propValidator([PROP_TYPES.NUMBER], false, 0)
  },
  methods: {
    onExpand() {
      this.$emit('expand', true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/local/styles/_article-body.scss';

.expanded-content__wrapper {
  .see-more__arrow-down {
    margin: 0 auto;
    border-color: $c--gray-light;
    cursor: pointer;
  }

  .expanded-content__content {
    padding-top: 20px;
  }

  .expanded-content__socials {
    margin: 20px 0;
  }

  /deep/ a {
    color: $c--main;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
