<template>
  <div class="publisher-details__wrapper">
    <div
      v-if="isPublisherImageVisible"
      class="publisher-details__publisher-image-wrapper"
      :class="publisherDynamicClass"
    >
      <a-image
        v-if="$helper.isNotAmpPage($route.name)"
        :src="publisherImage"
        :aspect-ratio="aspectRatios.articleFace"
        :alt="publisherName"
        v-bind="publisherLinkImageProps"
        :open-in-new-tab="false"
        class="publisher-details__publisher-image"
      />

      <a-link v-if="$helper.isAmpPage($route.name)" v-bind="publisherLinkProps">
        <amp-img
          :src="ampImageUrl"
          :alt="publisherName"
          layout="responsive"
          width="1"
          height="1"
        />
      </a-link>
    </div>
    <div class="publisher-details__name-and-date">
      <template v-if="!hidePublisher">
        <a-link
          v-if="publisherLinkProps"
          v-bind="publisherLinkProps"
          class="publisher-details__publisher-name font-sans semi-bold"
        >
          {{ publisherName }}
        </a-link>
        <span
          v-else
          class="publisher-details__publisher-name font-sans semi-bold"
        >
          {{ publisherName }}
        </span>
      </template>
      <a-visibility
        v-if="$helper.isNotAmpPage($route.name)"
        hide
        :on="[$breakpoint.mobile]"
      >
        <div class="publisher-details__date date">
          {{ $helper.formatDate.toDefault(date) }}
        </div>
      </a-visibility>
      <a-visibility show :on="[$breakpoint.mobile]">
        <div class="publisher-details__date date">
          {{ $helper.formatDate.toDateAndHours(date) }}
        </div>
      </a-visibility>
    </div>
  </div>
</template>

<script>
import { pathOr } from 'ramda'

import mixins from '@/utils/mixins'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { aspectRatios } from 'enums/aspectRatios'
import { ROUTE_NAME } from 'enums/routes'
import { DEFAULT_AUTHOR_LOGO } from 'enums/aws-files'
import { IMAGE_SIZE } from 'enums/images'

export default {
  name: 'APublisherDetails',
  mixins: [mixins.urlGenerators],
  props: {
    sponsorshipCompany: propValidator([PROP_TYPES.OBJECT], false),
    author: propValidator([PROP_TYPES.OBJECT], false),
    date: propValidator([PROP_TYPES.STRING]),
    hidePublisher: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  data() {
    return { aspectRatios }
  },
  computed: {
    publisherDynamicClass() {
      return {
        company: !!this.sponsorshipCompany,
        author: !this.sponsorshipCompany
      }
    },
    publisherImage() {
      if (!this.sponsorshipCompany) {
        const authorLogo = pathOr(null, ['photoUrl'], this.author)
        const defaultAuthorLogo = this.$helper.getLinkToFlBucketFile(
          DEFAULT_AUTHOR_LOGO
        )

        return authorLogo || defaultAuthorLogo
      }

      const sponsoredLogo = pathOr(null, ['logoUrl'], this.sponsorshipCompany)
      const companyLogo = pathOr(
        null,
        ['linkedCompany', 'logoUrl'],
        this.sponsorshipCompany
      )

      return sponsoredLogo || companyLogo || null
    },
    isPublisherImageVisible() {
      return !this.hidePublisher && !!this.publisherImage
    },
    publisherName() {
      const authorName = this.author.name
      const companyName = pathOr(null, ['name'], this.sponsorshipCompany)

      return companyName || authorName
    },
    ampImageUrl() {
      return this.$helper.replaceImageUrlSuffix({
        originalUrl: this.publisherImage,
        neededSize: this.sponsorshipCompany
          ? IMAGE_SIZE.WIDTH_150
          : IMAGE_SIZE.WIDTH_50
      })
    },
    linkedCompany() {
      return this.sponsorshipCompany && this.sponsorshipCompany.linkedCompany
    },
    companyUrl() {
      if (!this.linkedCompany) return null

      return this.generateCompanyUrl(this.linkedCompany)
    },
    authorLink() {
      if (!this.author || !this.author.slug) return null

      return {
        name: ROUTE_NAME.AUTHOR_AUTHOR,
        params: {
          author: this.author.slug
        }
      }
    },
    publisherLinkProps() {
      if (!this.sponsorshipCompany) {
        return {
          to: this.authorLink
        }
      }

      if (this.linkedCompany) {
        const { slug, categorySlug } = this.linkedCompany
        if (!slug || !categorySlug) return null

        return {
          to: this.companyUrl,
          trailingSlash: this.$helper.isLinkWithTrailingSlash(this.companyUrl)
        }
      } else {
        return null
      }
    },
    publisherLinkImageProps() {
      if (!this.sponsorshipCompany) {
        return {
          url: this.authorLink,
          linkTrailingSlash: undefined
        }
      }

      if (this.linkedCompany) {
        return {
          url: this.companyUrl,
          linkTrailingSlash: this.$helper.isLinkWithTrailingSlash(
            this.companyUrl
          )
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.publisher-details__wrapper {
  display: flex;
  align-items: center;

  .publisher-details__publisher-image-wrapper {
    margin-right: 10px;

    &.author {
      width: 50px;
    }

    &.company {
      width: 70px;

      @include mobile {
        width: 50px;
      }
    }
  }

  .publisher-details__publisher-image,
  /deep/ amp-img {
    border-radius: 50%;
    overflow: hidden;
  }

  .publisher-details__name-and-date {
    @include mobile {
      margin-right: 0;
    }
  }

  .publisher-details__publisher-name {
    margin-bottom: 2px;
    font-size: 14px;

    @include mobile {
      font-size: 12px;
    }
  }
}
</style>
