<template>
  <div class="article-slot__footer">
    <a-publisher-details
      :hide-publisher="hidePublisher"
      :sponsorship-company="sponsorshipCompany"
      :author="author"
      :date="date"
      class="article-slot__published-details"
    />

    <a-visibility v-if="!hideSocials" hide :on="[$breakpoint.mobile]">
      <div class="article-slot__socials">
        <slot name="socials" :socials="socials">
          <article-slot-socials
            :article-id="articleId"
            :socials="socials"
            :category="category"
            :slug="slug"
            :is-migrated="isMigrated"
            @update-comment-count="$emit('update-comment-count', $event)"
          />
        </slot>
      </div>
    </a-visibility>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import APublisherDetails from 'shared/APublisherDetails'
import ArticleSlotSocials from '../ArticleSlotSocials'

export default {
  name: 'ArticleSlotFooter',
  components: { APublisherDetails, ArticleSlotSocials },
  props: {
    articleId: propValidator([PROP_TYPES.STRING]),
    slug: propValidator([PROP_TYPES.STRING]),
    category: propValidator([PROP_TYPES.OBJECT], false),
    author: propValidator([PROP_TYPES.OBJECT], false),
    sponsorshipCompany: propValidator([PROP_TYPES.OBJECT], false),
    date: propValidator([PROP_TYPES.STRING]),
    socials: propValidator([PROP_TYPES.OBJECT], false),
    hidePublisher: propValidator([PROP_TYPES.BOOLEAN], false, false),
    hideSocials: propValidator([PROP_TYPES.BOOLEAN], false, false),
    isMigrated: propValidator([PROP_TYPES.BOOLEAN], false, false)
  }
}
</script>

<style lang="scss" scoped>
.article-slot__footer {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -10px;

  .article-slot__published-details {
    margin-top: 10px;
  }

  .article-slot__socials {
    margin-top: 10px;
    margin-left: auto;
  }
}
</style>
